import {GetTokenSilentlyOptions} from '@auth0/auth0-react'
import {GetTokenSilentlyVerboseResponse} from '@auth0/auth0-spa-js'

let getAccessTokenSilently: func
let logoutFunc: () => void
type func = {
	(
		options: GetTokenSilentlyOptions & {detailedResponse: true},
	): Promise<GetTokenSilentlyVerboseResponse>
	(options?: GetTokenSilentlyOptions): Promise<string>
	(
		options: GetTokenSilentlyOptions,
	): Promise<GetTokenSilentlyVerboseResponse | string>
}
export const sec = {
	getAccessTokenSilently: () => getAccessTokenSilently,
	setAccessTokenSilently: (func: func) => (getAccessTokenSilently = func),
	setLogoutFunc: (func: () => void) => (logoutFunc = func),
	getLogoutFunc: () => logoutFunc,
}
