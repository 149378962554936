import React, {useCallback, useMemo} from 'react'
import {
	Button,
	Dialog,
	DialogContent,
	DialogDescription,
	DialogTitle,
	InputLabel,
	SelectInput,
	TextInput,
	toast,
} from 'ui'
import {
	useAllVersions,
	useCreateVersion,
} from '~/api-client/version/versionHooks'
import {FormProvider, useForm} from 'react-hook-form'
import {useQueryClient} from '@tanstack/react-query'
import {useNavigate} from 'react-router-dom'
import {VersionStatus} from 'types'
import {format} from 'date-fns'
import {AutosizeTextarea} from '../ui/autosizeTextArea'

export type VersionCreationDialogProps = {
	open: boolean
	setOpen: (open: boolean) => void
	copyFromVersion?: string
}

type VersionCreationForm = {
	pricingVersion: string
	pricingDescription: string
	copyFromVersion: string
}

export default function VersionCreationDialog({
	open,
	setOpen,
	copyFromVersion,
}: VersionCreationDialogProps): React.ReactElement {
	const createVersionMutation = useCreateVersion()
	const queryClient = useQueryClient()
	const navigate = useNavigate()

	const getVersionNamePrefix = useCallback(() => {
		const date = new Date()
		return `pricing ${format(date, 'yy.MM.dd')}-`
	}, [])

	const methods = useForm<VersionCreationForm>({
		values: {
			pricingVersion: getVersionNamePrefix(),
			pricingDescription: '',
			copyFromVersion: copyFromVersion,
		},
		mode: 'onChange',
	})

	const {data: allVerisons} = useAllVersions()

	const handleCreateVersion = useCallback(() => {
		const newVersion = methods.getValues()
		createVersionMutation.mutate(
			{
				name: newVersion.pricingVersion,
				description: newVersion.pricingDescription,
				copyFrom: newVersion.copyFromVersion || undefined,
			},
			{
				onSettled: async (data, error) => {
					if (error) {
						toast({
							title: 'Version Creation',
							status: 'error',
							desc: 'Failed to create version',
						})
					} else {
						setOpen(false)
						methods.reset()
						await queryClient.invalidateQueries({queryKey: ['allVersions']})
						navigate(`/pricing/${data.id}/info`)
						toast({
							title: 'Version Creation',
							status: 'success',
							desc: 'Version created successfully',
						})
					}
				},
			},
		)
	}, [createVersionMutation, methods, navigate, queryClient, setOpen])

	const options = useMemo(
		() => [
			{
				value: '',
				label: 'none',
				disabled: false,
			},
			...(allVerisons ?? [])
				.filter(v => v.status !== VersionStatus.Deleted)
				.map(v => ({
					value: v.id,
					label: v.name,
					disabled: false,
				})),
		],
		[allVerisons],
	)

	return (
		<FormProvider {...methods}>
			<div className="relative text-sm">
				<Dialog open={open} onOpenChange={setOpen}>
					<DialogContent className="border-dark min-w-[800px] p-6 gap-[60px] pt-[24px]">
						<DialogTitle className="text-md font-bold">
							Create a new pricing version
						</DialogTitle>
						<DialogDescription className="text-sm text-gray-medium hidden" />
						<div className="flex flex-col">
							<InputLabel
								label="Pricing version"
								className="font-bold w-full text-sm"
								htmlFor="pricingVersion"
							/>
							<TextInput
								name="pricingVersion"
								cy-data="pricingVersion"
								placeholder="Pricing YY.MM.DD-X"
								className="text-sm"
								methods={methods}
							/>
						</div>
						<div className="flex flex-col gap-3">
							<InputLabel
								label="Description"
								className="font-bold w-full text-sm"
								htmlFor="pricingDescription"
							/>

							<AutosizeTextarea
								placeholder="Please describe the pricing changes here ..."
								cy-data="pricingDescription"
								minHeight={24}
								className="text-sm px-1 max-h-[30px] overflow-hidden"
								name="pricingDescription"
								methods={methods}
								isRequired
							/>
						</div>
						<div className="flex flex-col">
							<InputLabel
								label="Copy from version"
								className="font-bold w-full text-sm"
								htmlFor="copyFromVersion"
							/>
							<SelectInput
								name="copyFromVersion"
								cy-data="copyFromVersion"
								options={options}
								placeholderClassName="text-sm text-gray-medium"
								optionClassName="text-sm"
								methods={methods}
							/>
						</div>
						<div className="flex justify-end gap-6">
							<Button
								className="m-0 h-[40px]"
								onClick={() => {
									setOpen(false)
									methods.reset()
								}}
							>
								Cancel
							</Button>
							<Button
								variant="primary"
								className="m-0 h-[40px]"
								onClick={handleCreateVersion}
							>
								Create
							</Button>
						</div>
					</DialogContent>
				</Dialog>
			</div>
		</FormProvider>
	)
}
