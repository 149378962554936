import axios, {AxiosRequestConfig} from 'axios'
import {sec} from '../utils/security'
import {apiConfig} from './apiConfig'
import * as Sentry from '@sentry/react'

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
	filename?: string
	isDownload?: boolean
}

const client = async <T>(
	endpoint: string,
	{
		data = null,
		headers: customHeaders = {},
		method = '',
		responseType = 'json',
		...customConfig
	}: CustomAxiosRequestConfig,
) => {
	const auth0Data = await JSON.parse(
		localStorage.getItem(
			`@@auth0spajs@@::${apiConfig.client_id}::${apiConfig.audience}::openid read:current_user update:current_user_metadata profile email`,
		) ?? '{}',
	)
	let accessToken = auth0Data?.body?.access_token
	if (accessToken == undefined) {
		accessToken = await sec.getAccessTokenSilently()({
			cacheMode: 'on',
			authorizationParams: {
				audience: apiConfig.audience,
				scope: apiConfig.scope,
			},
		})
	}

	const getHttpMethod = (data: string, method: string) => {
		if (method) return method
		if (data) return 'POST'
		return 'GET'
	}
	const config = {
		url: `${apiConfig.baseUrl}/${endpoint}`,
		method: getHttpMethod(JSON.stringify(data), method),
		responseType: responseType,
		data: data ? data : undefined,
		headers: {
			Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
			'Content-Type':
				Object.keys(customHeaders).includes('Content-Type') ?
					customHeaders['Content-Type']
				: data ? 'application/json'
				: undefined,
			...customHeaders,
		},
		...customConfig,
	}
	return axios<T>(config)
		.then(response => {
			if (response.status === 401) {
				return Promise.reject({message: 'Please re-authenticate.'})
			}

			return response.data
		})
		.catch(error => {
			/*
			Because we have to handle cases when heatExchanger does not have spreadsheet 
			backend returns 400 but we still the page to load without error
			We will return null for this case
			The reason why this block code is here is because it has to be in the lowest level of try catch block.
			*/
			if (
				error.response?.status === 400 &&
				error.response?.data?.message === 'SpreadSheet not found!'
			) {
				console.log('error 400')
				return Promise.resolve(null)
			}
			if (error.response.status === 401) {
				console.log(401)
				console.log(error.response)
				sec.getLogoutFunc()()
			}
			Sentry.captureException(error)
			if (error.response) {
				return Promise.reject(error.response.data)
			} else if (error.request) {
				return Promise.reject(error.request)
			} else {
				return Promise.reject(error.message)
			}
		})
}

export default client
