import React from 'react'
import {Coffee} from 'lucide-react'

export default function CoffeeView(): React.ReactElement {
	return (
		<div className="flex-1 h-full flex justify-center">
			<div className="flex flex-col items-center mt-[200px]">
				<Coffee size={52} strokeWidth={1} />
				<p>Nothing here but a cup of coffee</p>
			</div>
		</div>
	)
}
