import {useEffect, useState} from 'react'

export function useWindowSize(): {width: number; height: number} {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return windowSize
}

interface UseAutosizeTextAreaProps {
	textAreaRef: HTMLTextAreaElement | null
	minHeight?: number
	maxHeight?: number
	triggerAutoSize: string
}

export const useAutosizeTextArea = ({
	textAreaRef,
	triggerAutoSize,
	maxHeight = Number.MAX_SAFE_INTEGER,
	minHeight = 0,
}: UseAutosizeTextAreaProps) => {
	const [init, setInit] = useState(true)
	useEffect(() => {
		// We need to reset the height momentarily to get the correct scrollHeight for the textarea
		const offsetBorder = 2
		if (textAreaRef) {
			if (init) {
				textAreaRef.style.minHeight = `${minHeight + offsetBorder}px`
				if (maxHeight > minHeight) {
					textAreaRef.style.maxHeight = `${maxHeight}px`
				}
				setInit(false)
			}
			textAreaRef.style.height = `${minHeight + offsetBorder}px`
			const scrollHeight = textAreaRef.scrollHeight
			// We then set the height directly, outside of the render loop
			// Trying to set this with state or a ref will product an incorrect value.
			if (scrollHeight > maxHeight) {
				textAreaRef.style.height = `${maxHeight}px`
			} else {
				textAreaRef.style.height = `${scrollHeight + offsetBorder}px`
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [textAreaRef, triggerAutoSize])
}
