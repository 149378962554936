import React from 'react'
import {Link} from 'react-router-dom'
import {Tooltip, TooltipContent, TooltipTrigger, cn} from 'ui'
import {navItemGroup} from '~/const/menuView'

interface ToolLinksProps {
	isSidebarCollapsed: boolean
	activeRoute: string
}

export const ToolLinks: React.FC<ToolLinksProps> = ({
	isSidebarCollapsed,
	activeRoute,
}) => {
	const localPathname =
		'https://develop.sales.vahterus.dev/cpq/tool/single-calculator'
	const prodPathname = ''
	const externalPathname =
		import.meta.env.VITE_ENVIRONMENT == 'local' ? localPathname : prodPathname

	return (
		<nav className="flex-grow">
			{navItemGroup.map((itemGroup, index) => {
				return (
					<ul
						key={index}
						className="my-6 pb-6 flex flex-col items-stretch border-b border-b-gray-300"
					>
						{!isSidebarCollapsed && (
							<span className={cn('ml-6 my-2 text-gray-600 text-sm leading-4')}>
								TOOLS
							</span>
						)}
						{itemGroup.map((item, index) => {
							return (
								<Link
									key={index}
									to={item.isSameDomain ? item.routeId : externalPathname}
								>
									<li
										className={cn('w-full', {
											'flex transition-colors duration-200 group': true,
											'justify-center p-4 bg-black': isSidebarCollapsed,
											'p-4 pl-6 gap-4': !isSidebarCollapsed,
											'hover:bg-gray-200':
												!isSidebarCollapsed &&
												!activeRoute.includes(item.params),
											'bg-white text-black':
												isSidebarCollapsed && activeRoute.includes(item.params),
											'bg-black text-white':
												!isSidebarCollapsed &&
												activeRoute.includes(item.params),
										})}
									>
										<div
											className={cn('flex items-center ml-0', {
												'gap-4 w-full ml-2': !isSidebarCollapsed,
											})}
										>
											{isSidebarCollapsed ?
												<Tooltip placement="right-end">
													<TooltipTrigger className="w-full">
														{item.icon({
															color: 'white',
															isSidebarCollapsed,
															isActive: activeRoute.includes(item.params),
														})}
													</TooltipTrigger>
													<TooltipContent>{item.label}</TooltipContent>
												</Tooltip>
											:	item.icon({
													color: 'black',
													isSidebarCollapsed,
													isActive: activeRoute.includes(item.params),
												})
											}
											<span className="text-sm font-normal">
												{isSidebarCollapsed ? null : item.label}
											</span>
										</div>
									</li>
								</Link>
							)
						})}
					</ul>
				)
			})}
		</nav>
	)
}
