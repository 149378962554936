import React from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {ChevronsLeftIcon} from 'lucide-react'
import {useLocation} from 'react-router-dom'
import {BasicVersionDto} from 'types'
import {Avatar, TooltipComponent} from 'ui'
import vahterusLogo from '~/assets/imgs/vahterus-logo-black.png'
import {cn} from '~/utils/style'
import {FeedbackLinks} from './FeedbackLinks'
import {ToolLinks} from './ToolLinks'
import VersionCreationDialog, {
	VersionCreationDialogProps,
} from './VersionCreationDialog'
import WorkspacesGroup from './WorkspacesGroup'

export type VersionGroups = Record<string, PureVersion[]>

export type PureVersion = Omit<BasicVersionDto, 'price_sheets'>

export function MenuView() {
	const [versionCreationData, setVersionCreationData] =
		React.useState<VersionCreationDialogProps>({
			open: false,
			setOpen: () => setVersionCreationData(prev => ({...prev, open: false})),
			copyFromVersion: undefined,
		})
	const [windowWidthSize, setWindowWidthSize] = React.useState(
		window.innerWidth,
	)
	const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false)
	const [activeRoute, setActiveRoute] = React.useState('')

	const {user, logout} = useAuth0()
	const location = useLocation()

	const handleLogout = React.useCallback(() => {
		logout({logoutParams: {returnTo: window.location.origin}})
	}, [logout])

	React.useEffect(() => {
		const handleWindowResize = () => {
			setWindowWidthSize(window.innerWidth)
		}

		window.addEventListener('resize', handleWindowResize)

		if (windowWidthSize <= 1880) {
			setIsSidebarCollapsed(true)
		}
	}, [windowWidthSize])

	React.useEffect(() => {
		setActiveRoute(location.pathname)
	}, [location])

	return (
		<div
			className={cn(
				'w-60 max-w-60 min-w-60 h-full flex flex-col z-20 transition-all duration-300 ease-in-out',
				{
					'bg-black w-16 max-w-16 min-w-16': isSidebarCollapsed,
					'bg-white border-r border-r-gray-300': !isSidebarCollapsed,
				},
			)}
		>
			<div
				className={cn({
					'flex items-center justify-center transition-none': true,
					'p-6 justify-between pr-2': !isSidebarCollapsed,
					'py-4 justify-center': isSidebarCollapsed,
				})}
			>
				{!isSidebarCollapsed && <img className="h-3" src={vahterusLogo} />}
				<button
					className={cn('grid place-content-center')}
					onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
				>
					<ChevronsLeftIcon
						strokeWidth={1}
						color={isSidebarCollapsed ? 'white' : 'black'}
						className={cn({
							'w-7 h-7 transition duration-500 ease-in-out': true,
							'transform rotate-180': isSidebarCollapsed,
						})}
					/>
				</button>
			</div>

			<WorkspacesGroup
				isSidebarCollapsed={isSidebarCollapsed}
				activeRoute={activeRoute}
			/>

			<div className="w-full h-[1px] bg-gray-200" />

			<ToolLinks
				isSidebarCollapsed={isSidebarCollapsed}
				activeRoute={activeRoute}
			/>

			<FeedbackLinks
				isSidebarCollapsed={isSidebarCollapsed}
				className="hidden"
			/>

			<div className="w-full h-[1px] bg-gray-200" />

			<div
				className={cn('flex items-start justify-between mx-0', {
					'mx-6': !isSidebarCollapsed,
				})}
			>
				<TooltipComponent
					content={
						<div className="text-xs bg-white text-gray-dark flex flex-col w-[148px]">
							<button
								className="text-xs leading-xs p-3 pr-0 w-full flex hover:bg-gray-50"
								onClick={handleLogout}
							>
								Logout
							</button>
						</div>
					}
					trigger={
						<span
							className={cn('flex h-16 items-center w-fit', {
								'w-full justify-center': isSidebarCollapsed,
							})}
						>
							<Avatar username={user.name ?? 'No Name'} src={user.picture} />
						</span>
					}
					side="left"
					align="center"
					sideOffset={-4}
					tooltipArrowClassName="fill-[transparent]"
				/>

				{!isSidebarCollapsed && (
					<div className="text-sm flex items-center h-full">
						{import.meta.env.VITE_REGION == 'CN' ? 'China' : 'Global'}
					</div>
				)}
			</div>
			<VersionCreationDialog {...versionCreationData} />
		</div>
	)
}
