import React from 'react'
import {Tooltip, TooltipContent, TooltipTrigger, cn} from 'ui'
import {feedbackItemGroup} from '~/const/menuView'
import {useAuth0} from '@auth0/auth0-react'

interface FeedbackLinksProps {
	isSidebarCollapsed: boolean
	className?: string
}

export const FeedbackLinks: React.FC<FeedbackLinksProps> = ({
	isSidebarCollapsed,
	className,
}) => {
	const {user} = useAuth0()

	return (
		<div className={cn('border-t border-gray-200 py-4', className)}>
			{isSidebarCollapsed ? null : (
				<div className="pl-6 my-3 text-gray-600 text-sm leading-4">HELP</div>
			)}
			<ul
				className={cn({
					'my-6': isSidebarCollapsed,
				})}
			>
				{feedbackItemGroup.map((item, index) => {
					return (
						<a
							key={index}
							href={item.href(user['http://vahterus/roles'])}
							target="_blank"
							rel="noopener noreferrer"
						>
							<li
								className={cn('w-full', {
									'flex transition-colors duration-200 group': true,
									'justify-center p-4 bg-black': isSidebarCollapsed,
									'p-4 pl-6 gap-4': !isSidebarCollapsed,
									'hover:bg-gray-200': !isSidebarCollapsed,
									'hover:bg-gray-dark': isSidebarCollapsed,
								})}
							>
								<div
									className={cn('flex items-center ml-0', {
										'gap-4 w-full ml-2': !isSidebarCollapsed,
									})}
								>
									{isSidebarCollapsed ?
										<Tooltip placement="right-end">
											<TooltipTrigger className="w-full">
												{item.icon({
													color: 'white',
													isSidebarCollapsed,
													isActive: false,
												})}
											</TooltipTrigger>
											<TooltipContent>{item.label}</TooltipContent>
										</Tooltip>
									:	item.icon({
											color: 'black',
											isSidebarCollapsed,
											isActive: false,
										})
									}{' '}
									<span className="text-sm font-normal text-gray-dark">
										{isSidebarCollapsed ? null : item.label}
									</span>
								</div>
							</li>
						</a>
					)
				})}
			</ul>
		</div>
	)
}
