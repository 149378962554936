import React from 'react'
import {Link} from 'react-router-dom'
import {Avatar, cn, Tooltip, TooltipContent, TooltipTrigger} from 'ui'
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '~/components/ui/accordion'
import {workspacesGroup} from '~/const/menuView'

type WorkspacesGroupProps = {
	isSidebarCollapsed: boolean
	activeRoute: string
}

export default function WorkspacesGroup({
	isSidebarCollapsed,
	activeRoute,
}: WorkspacesGroupProps): React.ReactElement {
	return (
		<Accordion
			defaultValue={workspacesGroup[0].name}
			type="single"
			collapsible
			className="max-h-64 overflow-y-auto overflow-x-hidden"
		>
			{workspacesGroup.map(item => (
				<AccordionItem value={item.name} className="border-b-0" key={item.name}>
					<AccordionTrigger
						className={cn(
							'rounded-none',
							'hover:no-underline hover:bg-gray-50 p-4 mt-2 text-gray-medium',
						)}
						noIcon={isSidebarCollapsed}
					>
						<div className="flex gap-2 items-center">
							<Avatar
								username={item.name}
								className="bg-success text-white font-bold text-xs"
							/>
							{!isSidebarCollapsed && (
								<p className="font-bold text-sm leading-[18px] text-gray-dark">
									{item.name}
								</p>
							)}
						</div>
					</AccordionTrigger>
					<AccordionContent>
						{item.children.map(child => (
							<Link key={child.name} to={''}>
								<li
									className={cn('w-full', {
										'flex transition-colors duration-200 group': true,
										'justify-center p-4 bg-black': isSidebarCollapsed,
										'p-4 pl-6 gap-4': !isSidebarCollapsed,
										'hover:bg-gray-200':
											!isSidebarCollapsed && !activeRoute.includes(child.name),
										'bg-white text-black':
											isSidebarCollapsed && activeRoute.includes(child.name),
										'bg-black text-white':
											!isSidebarCollapsed && activeRoute.includes(child.name),
									})}
								>
									<div
										className={cn('flex items-center ml-0', {
											'gap-4 w-full ml-2': !isSidebarCollapsed,
										})}
									>
										{isSidebarCollapsed ?
											<Tooltip placement="right-end">
												<TooltipTrigger className="w-full">
													{child.icon({
														color: 'white',
														isSidebarCollapsed,
														isActive: activeRoute.includes(child.name),
													})}
												</TooltipTrigger>
												<TooltipContent>{child.name}</TooltipContent>
											</Tooltip>
										:	child.icon({
												color: 'black',
												isSidebarCollapsed,
												isActive: activeRoute.includes(child.name),
											})
										}
										<span className="text-sm font-normal">
											{isSidebarCollapsed ? null : child.name}
										</span>
									</div>
								</li>
							</Link>
						))}
					</AccordionContent>
				</AccordionItem>
			))}
		</Accordion>
	)
}
