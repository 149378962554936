import posthog from 'posthog-js'
import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

const RouteChangeListener: React.FC = () => {
	const location = useLocation()

	useEffect(() => {
		posthog.capture('$pageview')
		if (location.pathname !== '/login' && location.pathname !== '/') {
			localStorage.setItem('loginCallbackUrl', location.pathname)
		}
	}, [location])

	return null
}

export default RouteChangeListener
