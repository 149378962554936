import React from 'react'
import {
	BookOpenText,
	Calculator,
	CircleDollarSign,
	MessageCircleQuestion,
	MessageCircleWarning,
	ReceiptEuro,
	Settings,
	SlidersHorizontal,
} from 'lucide-react'
import {cn} from '~/utils/style'

export interface FeedbackItem {
	label: string
	href: (userRoles: string[]) => string
	icon: React.FC<{
		color: 'white' | 'black'
		isSidebarCollapsed: boolean
		isActive: boolean
	}>
}

export interface NavItem {
	label: string
	routeId: string
	params?: string
	icon: React.FC<{
		color: 'white' | 'black'
		isSidebarCollapsed: boolean
		isActive: boolean
	}>
	isSameDomain?: boolean
}

export interface NavItemGroup {
	navItemGroup: NavItem[]
}

export type WorkspaceItem = {
	name: string
	icon?: React.FC<{
		color: 'white' | 'black'
		isSidebarCollapsed: boolean
		isActive: boolean
	}>
	children?: WorkspaceItem[]
}

export const navItemGroup: NavItem[][] = [
	[
		{
			label: 'Sizing calculation',
			routeId: '/cpq/tool/single-calculator',
			params: '/cpq/tool/single-calculator',
			icon: ({isSidebarCollapsed, isActive}) => (
				<SlidersHorizontal
					strokeWidth={1.5}
					className={cn('w-5 h-5 transition-none', {
						'stroke-white group-hover:stroke-white':
							isSidebarCollapsed && !isActive,
						'stroke-black': isSidebarCollapsed && isActive,
					})}
				/>
			),
			isSameDomain: false,
		},
		{
			label: 'Pricing',
			routeId: '/pricing',
			params: '/pricing',
			icon: ({isSidebarCollapsed, isActive}) => (
				<Calculator
					strokeWidth={1.5}
					className={cn('w-5 h-5 transition-none', {
						'stroke-white group-hover:stroke-white':
							isSidebarCollapsed && !isActive,
						'stroke-black': isSidebarCollapsed && isActive,
					})}
				/>
			),
			isSameDomain: true,
		},
	],
]

export const feedbackItemGroup: FeedbackItem[] = [
	{
		label: 'Report an error',
		href: () =>
			'https://forms.clickup.com/25753540/f/rhxy4-14098/9QZ1NZ6EEMXA16MUH2',
		icon: ({isSidebarCollapsed, isActive}) => (
			<MessageCircleWarning
				strokeWidth={1.5}
				className={cn('w-5 h-5 transition-none', {
					'stroke-white group-hover:stroke-white':
						isSidebarCollapsed && !isActive,
					'stroke-black': isSidebarCollapsed && isActive,
				})}
			/>
		),
	},
	{
		label: 'Share a suggestion',
		href: () =>
			'https://forms.clickup.com/25753540/f/rhxy4-14118/JP31WIZARKVKCOBXLU',
		icon: ({isSidebarCollapsed, isActive}) => (
			<MessageCircleQuestion
				strokeWidth={1.5}
				className={cn('w-5 h-5 transition-none', {
					'stroke-white group-hover:stroke-white':
						isSidebarCollapsed && !isActive,
					'stroke-black': isSidebarCollapsed && isActive,
				})}
			/>
		),
	},
	{
		label: 'CPQ Instructions',
		href: () => 'https://wiki.vahterus.cloud/internal/',
		icon: ({isSidebarCollapsed, isActive}) => (
			<BookOpenText
				strokeWidth={1.5}
				className={cn('w-5 h-5 transition-none', {
					'stroke-white group-hover:stroke-white':
						isSidebarCollapsed && !isActive,
					'stroke-black': isSidebarCollapsed && isActive,
				})}
			/>
		),
	},
]

export const workspacesGroup: WorkspaceItem[] = [
	{
		name: 'Workspace 1',
		children: [
			{
				name: 'Offers',
				icon: ({isSidebarCollapsed, isActive}) => (
					<CircleDollarSign
						strokeWidth={1.5}
						className={cn('w-5 h-5 transition-none', {
							'stroke-white group-hover:stroke-white':
								isSidebarCollapsed && !isActive,
							'stroke-black': isSidebarCollapsed && isActive,
						})}
					/>
				),
			},
			{
				name: 'Orders',
				icon: ({isSidebarCollapsed, isActive}) => (
					<ReceiptEuro
						strokeWidth={1.5}
						className={cn('w-5 h-5 transition-none', {
							'stroke-white group-hover:stroke-white':
								isSidebarCollapsed && !isActive,
							'stroke-black': isSidebarCollapsed && isActive,
						})}
					/>
				),
			},
			{
				name: 'Admin',
				icon: ({isSidebarCollapsed, isActive}) => (
					<Settings
						strokeWidth={1.5}
						className={cn('w-5 h-5 transition-none', {
							'stroke-white group-hover:stroke-white':
								isSidebarCollapsed && !isActive,
							'stroke-black': isSidebarCollapsed && isActive,
						})}
					/>
				),
			},
		],
	},
]
