/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'
import * as React from 'react'
import {useImperativeHandle} from 'react'
import {useAutosizeTextArea} from '~/utils/hooks'
import {UseFormReturn} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'

import {cn} from '~/utils/style'

export type AutosizeTextAreaRef = {
	textArea: HTMLTextAreaElement
	maxHeight: number
	minHeight: number
}

type AutosizeTextAreaProps = {
	maxHeight?: number
	minHeight?: number
	methods?: UseFormReturn<any>
	name: string
	isRequired?: boolean
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>

export const AutosizeTextarea = React.forwardRef<
	AutosizeTextAreaRef,
	AutosizeTextAreaProps
>(
	(
		{
			maxHeight = Number.MAX_SAFE_INTEGER,
			minHeight = 52,
			className,
			name,
			style,
			methods,
			isRequired,
			...props
		}: AutosizeTextAreaProps,
		ref: React.Ref<AutosizeTextAreaRef>,
	) => {
		const {
			formState: {errors},
		} = methods

		const {register} = React.useMemo(
			() => methods || {register: () => {}},
			[methods],
		)

		const registeredField = register(name, {
			required: isRequired ? 'Required field' : false,
		})

		const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null)
		const [triggerAutoSize, setTriggerAutoSize] = React.useState('')

		useAutosizeTextArea({
			textAreaRef: textAreaRef.current,
			triggerAutoSize: triggerAutoSize,
			maxHeight,
			minHeight,
		})

		useImperativeHandle(ref, () => ({
			textArea: textAreaRef.current as HTMLTextAreaElement,
			focus: () => textAreaRef.current?.focus(),
			maxHeight,
			minHeight,
		}))

		const currentVal = methods?.watch(name)

		React.useEffect(() => {
			setTriggerAutoSize(currentVal as string)
		}, [props?.defaultValue, currentVal])

		return (
			<div>
				<textarea
					{...props}
					{...registeredField}
					ref={e => {
						textAreaRef.current = e
						if (registeredField && registeredField.ref) {
							registeredField.ref(e)
						}
					}}
					onChange={e => {
						setTriggerAutoSize(e.target.value)
						if (registeredField && registeredField.onChange) {
							registeredField.onChange(e)
						}
					}}
					className={cn(
						'flex w-full bg-background p-0 py-1 text-sm placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 border-0 border-b border-black focus:border-black focus:ring-0 focus:outline-none h-auto',
						{'border border-error focus:border-error': !!errors?.[name]},
						className,
					)}
					style={style}
				/>

				<ErrorMessage
					errors={errors}
					name={name}
					render={({message}) => (
						<p className={`mt-2 z-10 text-xs text-error w-fit`}>{message}</p>
					)}
				/>
			</div>
		)
	},
)
AutosizeTextarea.displayName = 'AutosizeTextarea'
