import {
	BasicVersionDto,
	CreateVersionDto,
	FullVersionDto,
	PricingSheets,
	UpdateVersionDto,
} from 'types'
import client from '../apiClient'

export const createVersion = async (
	data: CreateVersionDto,
): Promise<BasicVersionDto> => {
	return client(`pricing-api/versions`, {
		method: 'POST',
		data,
		headers: {
			'Content-Type': 'application/json',
		},
	})
}

export const getVersionById = async (id: string): Promise<FullVersionDto> => {
	return client(`pricing-api/versions/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	})
}

export const getAllVersions = async (): Promise<BasicVersionDto[]> => {
	return client(`pricing-api/versions`, {
		method: 'GET',
	})
}

export const updateVersionInfoById = async (
	id: string,
	data: UpdateVersionDto,
): Promise<FullVersionDto> => {
	return client(`pricing-api/versions/${id}`, {
		method: 'PATCH',
		data,
		headers: {
			'Content-Type': 'application/json',
		},
	})
}

export const getPricingSheetByVersionId = async (id: string) => {
	return client(`pricing-api/pricing-sheet/version/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	})
}

export const updatePricingSheetsByVersionId = async (
	id: string,
	data: PricingSheets,
): Promise<FullVersionDto> => {
	return client(`pricing-api/pricing-sheet/${id}`, {
		method: 'PATCH',
		data,
		headers: {
			'Content-Type': 'application/json',
		},
	})
}
