import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {Auth0Provider} from '@auth0/auth0-react'
import {apiConfig} from './api-client/apiConfig'
import LoadingText from './components/LoadingText'

const queryClient = new QueryClient({
	defaultOptions: {queries: {retry: 5, retryDelay: 1000}},
})

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<React.Suspense fallback={<LoadingText />}>
			<Auth0Provider
				domain={apiConfig.domain}
				clientId={apiConfig.client_id}
				authorizationParams={{
					redirect_uri: `${window.location.origin}`,
					audience: apiConfig.audience,
					scope: apiConfig.scope,
				}}
				cacheLocation="localstorage"
			>
				<QueryClientProvider client={queryClient}>
					<App />
					{import.meta.env.VITE_ENVIRONMENT === 'local' && (
						<ReactQueryDevtools initialIsOpen={false} />
					)}
				</QueryClientProvider>
			</Auth0Provider>
		</React.Suspense>
	</React.StrictMode>,
)
