import React from 'react'
import {ToastContainer} from 'ui'
import './App.css'

import RouteWrapper from './routers/router'

import {useWindowSize} from './utils/hooks'
import CoffeeView from './components/CoffeeView'
import {TABLET_BREAKPOINT} from './utils/style'

const App: React.FC = () => {
	const {width: screenWidth} = useWindowSize()

	const isTablet = screenWidth <= TABLET_BREAKPOINT

	if (isTablet) {
		return <CoffeeView />
	}

	return (
		<>
			<ToastContainer className={'z-500000'} />
			<RouteWrapper />
		</>
	)
}

export default App
