import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {useNavigate, useParams} from 'react-router-dom'
import {
	CreateVersionDto,
	PricingSheets,
	UpdateVersionDto,
	VersionStatus,
} from 'types'
import {toast} from 'ui'
import {
	createVersion,
	getAllVersions,
	getPricingSheetByVersionId,
	getVersionById,
	updatePricingSheetsByVersionId,
	updateVersionInfoById,
} from './versionApi'

export function useAllVersions() {
	return useQuery({
		queryKey: ['allVersions'],
		queryFn: getAllVersions,
	})
}

export function useCreateVersion() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: (data: CreateVersionDto) => createVersion(data),
		onSettled: async (_, error) => {
			if (!error) {
				await queryClient.invalidateQueries({queryKey: ['allVersions']})
			}
		},
	})
}

export function useVersion(id: string) {
	return useQuery({
		queryKey: ['version', {id}],
		queryFn: () => getVersionById(id),
		enabled: !!id,
	})
}

export const usePricingSheet = (versionId: string) => {
	return useQuery({
		queryKey: [versionId],
		queryFn: () => getPricingSheetByVersionId(versionId),
		enabled: !!versionId,
	})
}

export function useUpdateVersionInfo() {
	const queryClient = useQueryClient()
	const {versionId} = useParams()
	const navigate = useNavigate()
	return useMutation({
		mutationFn: (versionUpdate: {id: string; data: UpdateVersionDto}) =>
			updateVersionInfoById(versionUpdate.id, versionUpdate.data),
		onSettled: async (_, error, variables) => {
			if (error) {
				toast({
					title: 'Version Update',
					status: 'error',
					desc: `Failed to update version`,
				})
			} else {
				toast({
					title: 'Version Update',
					status: 'success',
					desc: `Version updated successfully`,
				})
				if (
					variables.data.status === VersionStatus.Deleted &&
					versionId === variables.id
				) {
					navigate('/pricing')
				}
				await queryClient.invalidateQueries({queryKey: ['allVersions']})
				await queryClient.invalidateQueries({
					queryKey: ['version', {id: variables.id}],
				})
			}
		},
	})
}

export function useUpdatePricingSheet() {
	return useMutation({
		mutationFn: (data: {versionId: string; pricingSheets: PricingSheets}) =>
			updatePricingSheetsByVersionId(data.versionId, data.pricingSheets),
	})
}
