import React from 'react'
import {DotPulse} from '@uiball/loaders'
import {cn} from 'ui'

interface FullPageLoadingProp {
	loadingText?: string
	className?: string
}
const FullPageLoading: React.FC<FullPageLoadingProp> = ({
	loadingText,
	className,
}) => {
	return (
		<div
			className={cn(
				'flex items-center justify-center w-full h-screen',
				className,
			)}
		>
			<div className="flex justify-center items-center space-x-1 text-sm text-gray-700">
				<DotPulse size={35} color="black" />
				<div className="pl-5 font-bold">
					{loadingText ? loadingText : 'Loading ...'}
				</div>
			</div>
		</div>
	)
}

export default FullPageLoading
