import React from 'react'
import RouteChangeListener from './RouteChangeListener'

const CustomErrorBoundary = (): React.ReactElement => {
	return (
		<>
			<RouteChangeListener />
			<div className="p-2">
				<strong className="text-[1rem]">Something went wrong!</strong>
				<pre className="text-xs border border-error rounded p-1.5 text-error overflow-auto mt-2 font-bold">
					<code>{'An unexpected error occurred'}</code>
				</pre>
			</div>
		</>
	)
}

export default CustomErrorBoundary
