import {useAuth0} from '@auth0/auth0-react'
import React, {Suspense} from 'react'
import {
	Navigate,
	Outlet,
	RouterProvider,
	createBrowserRouter,
	useLocation,
} from 'react-router-dom'
import CoffeeView from '../components/CoffeeView'
import FullPageLoading from '../components/FullPageLoading'
import {MenuView} from '../components/versionMenu/MenuView'
import CustomErrorBoundary from './CustomErrorBoundary'

const LoginPage = React.lazy(() => import('~/pages/LoginPage'))
const VersionListPage = React.lazy(
	() => import('~/components/versionView/VersionList'),
)
const VersionViewPage = React.lazy(
	() => import('~/components/versionView/VersionView'),
)
const InfoViewPage = React.lazy(
	() => import('~/components/versionView/InfoView'),
)
const FormulasViewPage = React.lazy(
	() => import('~/components/versionView/Formula/FormulasView'),
)
const PricingSheetViewPage = React.lazy(
	() => import('~/components/versionView/PricingSheetView'),
)

export const WithLoader: React.FC<{children: React.ReactNode}> = ({
	children,
}) => {
	return <Suspense fallback={<FullPageLoading />}>{children}</Suspense>
}

export const RequireAuth: React.FC<{children: React.ReactNode}> = ({
	children,
}) => {
	const {isAuthenticated, isLoading} = useAuth0()
	const location = useLocation()

	if (isLoading) {
		return <FullPageLoading />
	}

	if (!isAuthenticated) {
		return <Navigate to="/login" state={{from: location}} />
	}

	return <>{children}</>
}

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<RequireAuth>
				<div className="flex h-screen">
					<MenuView />
					<div className="w-[1px] h-full bg-gray-200" />
					<CoffeeView />
				</div>
			</RequireAuth>
		),
		errorElement: <CustomErrorBoundary />,
	},
	{
		path: 'pricing',
		element: (
			<RequireAuth>
				<div className="flex h-screen">
					<MenuView />
					<div className="w-[1px] min-w-[1px] h-full bg-gray-200" />
					<Outlet />
				</div>
			</RequireAuth>
		),
		children: [
			{
				path: '',
				element: (
					<WithLoader>
						<VersionListPage />
					</WithLoader>
				),
				errorElement: <CustomErrorBoundary />,
			},
			{
				path: ':versionId',
				element: (
					<WithLoader>
						<VersionViewPage />
					</WithLoader>
				),
				children: [
					{
						path: 'info',
						element: (
							<WithLoader>
								<InfoViewPage />
							</WithLoader>
						),
						errorElement: <CustomErrorBoundary />,
					},
					{
						path: 'formulas',
						element: (
							<WithLoader>
								<FormulasViewPage />
							</WithLoader>
						),
						errorElement: <CustomErrorBoundary />,
					},
					{
						path: 'pricing-sheets',
						element: (
							<WithLoader>
								<PricingSheetViewPage />
							</WithLoader>
						),
						errorElement: <CustomErrorBoundary />,
					},
					{
						path: 'revenue-estimation',
						element: <CoffeeView />,
						errorElement: <CustomErrorBoundary />,
					},
				],
				errorElement: <CustomErrorBoundary />,
			},
		],
		errorElement: <CustomErrorBoundary />,
	},
	{
		path: 'login',
		element: (
			<WithLoader>
				<LoginPage />
			</WithLoader>
		),
		errorElement: <CustomErrorBoundary />,
	},
	{
		path: '*',
		element: <CoffeeView />,
		errorElement: <CustomErrorBoundary />,
	},
])

const RouteWrapper: React.FC = () => {
	return <RouterProvider router={router} />
}

export default RouteWrapper
